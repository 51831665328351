import React from 'react'
import image1 from "../assets/sistema_ganadero.jpg";
import image2 from "../assets/porks.jpg";
import image3 from "../assets/sovca.jpg";
import image4 from "../assets/equs.jpg";
import image5 from "../assets/agristema.jpg";
import image6 from "../assets/capacitacion.jpg";
import Card from './Card'

const cards =[
    {
        id:1,
        title:'Sistema Ganadero BOVS Web',
        url:'https://global.ruted.net/bovs/',
        content:'Sistema para el control y registro de ganado bovino',
        redirect:'',
        image:image1,
        btnText:'Ingresar',
    },
    {
        id:2,
        title:'Sistema PORKS Ceba',
        url:'https://ruted.net/producto/sistema-porks-ceba/',
        content:'Sistema para el control y registro de ganado porcino en ceba',
        redirect:'_blank',
        image:image2,
        btnText:'Ver más',
    },
    {
        id:3,
        title:'Sistema Ovino-Caprino',
        url:'https://global.ruted.net/sovca/',
        content:'Sistema para el control y registro de ganado ovino y caprino',
        redirect:'',
        image:image3,
        btnText:'Ingresar',
    },
    {
        id:4,
        title:'Sistema EQUUS',
        url:'https://ruted.net/producto/',
        content:'Sistema para el control y registro de ganado equino',
        redirect:'_blank',
        image:image4,
        btnText:'Ver más',
    },
    {
        id:5,
        title:'AGRISTEMA “Sistema para control y registros de cultivos”',
        url:'https://ruted.net/producto/',
        content:'Sistema para el control y registro de cultivos',
        redirect:'_blank',
        image:image5,
        btnText:'Ver más',
    },
    {
        id:6,
        title:'Capacitación',
        url:'https://ruted.net/producto/capacitacion-remota-1hr-registros-y-controles-de-produccion/',
        content:'Te brindamos capacitación en el uso de nuestros sistemas',
        redirect:'_blank',
        image:image6,
        btnText:'Ver más',
    }
]
function Cards() {
  return (
    <div className='container justify-content-center align-items-center'>
        <div className="row">
            {
                cards.map(card =>(
                    <div className="col-md-4" key={card.id}>
                        <Card title={card.title} imageSource={card.image} url={card.url} content={card.content} redirect={card.redirect} btnText={card.btnText}/>
                    </div>   
                ))
            }      
        </div>   
         
    </div>
  )
}

export default Cards
