import React from 'react'
import Cards from './components/Cards'
import './App.css'

function App() {
  return (
    <div className='App'>
        <Cards />
    </div>
  )
}

export default App
