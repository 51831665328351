import React from 'react'

function Card({title,imageSource,url,content,redirect,btnText}) {
  
  return (
    <div className="card text-center">
      <img src={imageSource} alt="" />
      <div className="card-body">
        <h4 className="card-title">{title}</h4>
        <p className="card-text text-secondary " >{content}</p>
        <a href={url} className="btn btn-outline-info" target={redirect} rel='noreferrer'>{btnText}</a>
      </div>       
    </div>
  )
}

export default Card
